// 导出属于支付宝支付成功路由规则
import Layout from '@/layout/default-layout.vue';
export default {
    name: 'pay',
    path: '/pay',
    component: Layout,
    redirect: '/store/storeList',
    alwaysShow: true,
    meta: {
        icon: 'el-icon-s-shop',
        title: '支付',
    },
    // 配置二级路的路由表
    children: [
        {
            name: 'paysuccess',
            path: 'paysuccess',
            component: () => import('@/components/ZfbPaySuccess/paySuccess'),
            meta: {
                icon: 'el-icon-files',
                title: '支付成功',
            },
        },
        // {
        //   name: 'storeLevel',
        //   path: 'storeLevel',
        //   component: () => import('@/views/store/storeLevel'),
        //   meta: {
        //     icon: 'el-icon-files',
        //     title: '店铺等级',
        //   },
        // },
    ],
};