import Vue from 'vue';
import {
  Select,
  Option,
  Pagination,
  Row,
  Col,
  Table,
  TableColumn,
  Card,
  Button,
  Form,
  FormItem,
  Input,
  DatePicker,
  Calendar,
  Image,
  Tooltip,
  Cascader,
  Header,
  Container,
  Main,
  Dialog,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  ColorPicker,
  Breadcrumb,
  BreadcrumbItem,
  Menu,
  Submenu,
  MenuItem,
  Aside,
  Backtop,
  Radio,
  RadioGroup,
  RadioButton,
  Tabs,
  TabPane,
  Tag,
  Divider,
  CascaderPanel,
  Checkbox,
  Switch,
  Popover,
  Tree,
  Upload,
  Rate,
  Avatar,
  Descriptions,
  DescriptionsItem,
  Step,
  Steps,
  PageHeader,
  CheckboxGroup,
  Autocomplete,
  Popconfirm,
  Icon,
  Carousel,
  CarouselItem,
  Link,
  MessageBox,
  progress,
  Empty
} from 'element-ui';

const components = [
  Select,
  Option,
  Pagination,
  Row,
  Col,
  Table,
  TableColumn,
  Card,
  Button,
  Form,
  FormItem,
  Input,
  DatePicker,
  Calendar,
  Image,
  Tooltip,
  Cascader,
  Header,
  Container,
  Main,
  Dialog,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  ColorPicker,
  Breadcrumb,
  BreadcrumbItem,
  Menu,
  Submenu,
  MenuItem,
  Aside,
  Backtop,
  Radio,
  RadioGroup,
  RadioButton,
  Tabs,
  TabPane,
  Tag,
  Divider,
  CascaderPanel,
  Checkbox,
  Switch,
  Popover,
  Tree,
  Upload,
  Rate,
  Avatar,
  Descriptions,
  DescriptionsItem,
  Step,
  Steps,
  PageHeader,
  CheckboxGroup,
  Autocomplete,
  Popconfirm,
  Icon,
  Carousel,
  CarouselItem,
  Link,
  MessageBox,
  progress,
  Empty
];

for (const cpn of components) {
  Vue.component(cpn.name, cpn);
}

Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
