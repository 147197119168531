// 导出属商户运营中心的路由规则
import Layout from '@/layout/default-layout.vue';
// 每个子模块 其实 都是外层是layout  组件位于layout的二级路由里面
export default {
  name: 'MerchantOperationsCenter',
  path: '/MerchantOperationsCenter',
  component: Layout,
  redirect: '/MerchantOperationsCenter/toolsList',
  alwaysShow: true,
  meta: {
    icon: 'el-icon-s-marketing',
    title: '商户运营中心',
  },
  // 配置二级路的路由表
  children: [
    {
      name: 'toolsList',
      path: 'toolsList',
      component: () => import('@/views/MerchantOperationsCenter/toolsList'),
      meta: {
        icon: 'el-icon-scissors',
        title: '工具列表',
      },
    },
    {
      name: 'customerList',
      path: 'customerList',
      component: () => import('@/views/MerchantOperationsCenter/customerList'),
      meta: {
        icon: 'el-icon-sell',
        title: '客服服务列表',
      },
    },
    {
      name: 'purchaseList',
      path: 'purchaseList',
      component: () => import('@/views/MerchantOperationsCenter/purchaseList'),
      meta: {
        icon: 'el-icon-sell',
        title: '购买列表',
      },
    },
  ],
};
