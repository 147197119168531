/**
 * 缓存省市区
 * 
 * await this.$store.dispatch('area/init') // 先初始化数据
 * const target = await this.$store.dispatch('area/formatAreaByCode', { provinceCode: ..., cityCode: ..., areaCode: ..., ... })
 * target {
 *    province: obj
 *    city: obj
 *    districts: obj
 *    region: string 省市区名称结合
 * }
 */

import { getScreeningAreaList } from '@/api/order';

const state = {
  areaArr: [],
  loading: false,
  initialized: false
};

const mutations = {
  SET_AREAARR(state, areaArr) {
    state.areaArr = areaArr
  },
  TOGGLE_LOADING(state, loading) {
    state.loading = loading
  },
  TOGGLE_INITIALIZED(state, initialized) {
    state.initialized = initialized
  },
};

const actions = {
  async init({ state, commit, rootState }) {
    if (state.loading || state.initialized) return
    commit('TOGGLE_LOADING', true)
    const res = await getScreeningAreaList()
    if (res && res instanceof Array) {
      commit('SET_AREAARR', res)
      commit('TOGGLE_INITIALIZED', true)
    }
    commit('TOGGLE_LOADING', false)
  },
  // 根据省市区code获取对应名称
  formatAreaByCode({ state, commit, rootState }, data) {
    const { provinceCode, cityCode, areaCode } = data
    let province;
    let city;
    let districts;
    province = state.areaArr.find(item => item.code === provinceCode)
    if (provinceCode === cityCode) {
      city = province
    } else if (province && province.children) {
      city = province.children.find(item => item.code === cityCode)
    }
    if (city && city.children) {
      districts = city.children.find(item => item.code === areaCode)
    }
    return {
      province,
      city,
      districts,
      region: (province ? province.name : '') + (city ? city.name : '') + (districts ? districts.name : '')
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
