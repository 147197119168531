const getters = {
  sidebar: (state) => state.app.sidebar,
  device: (state) => state.app.device,
  token: (state) => state.user.token,
  loginTime: (state) => state.user.loginTime,
  selectIdArrId: (state) => state.commodity.selectIdArrId, // 选中图片、视频的id集合
  selectData: (state) => state.commodity.selectData, // 选中图片、视频的数据
  page: (state) => state.commodity.page,
  userInfo: (state) => state.user.userInfo,
  username: (state) => state.user.username,
  userId: (state) => state.user.userId,
  themeColor: (state) => state.user.themeColor,
  openAudit: (state) => state.user.openAudit,
  menuList: (state) => {
    const menuList = state.user.userInfo
      ? JSON.parse(JSON.stringify(state.user.userInfo.sysMenuList || []))
      : [];
    menuList.sort((a, b) => (a.sort || 0) - (b.sort || 0));
    menuList.shift();//第一个元素删除全局权限
    return menuList;
  },
  defaultRoute: (state, getters) => {
    return _recurseGetLastRoute(getters.menuList[0]);
  },
};

function _recurseGetLastRoute(menu) {
  if (menu?.hideChildrenMenu) return menu;

  if (menu?.children && menu.children.length) {
    return _recurseGetLastRoute(menu.children[0]);
  } else {
    return menu;
  }
}

export default getters;
