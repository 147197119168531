// 导出属数据中心的路由规则
import Layout from '@/layout/default-layout.vue';
// 每个子模块 其实 都是外层是layout  组件位于layout的二级路由里面
export default {
    name: 'DataCenter',
    path: '/DataCenter',
    component: Layout,
    redirect: '/DataCenter/GaiLan',
    alwaysShow: true,
    meta: {
        icon: 'el-icon-s-data',
        title: '数据中心',
    },
    // 配置二级路的路由表
    children: [
        {
            name: 'GaiLan',
            path: 'GaiLan',
            component: () => import('@/views/DataCenter/GaiLan'),
            meta: {
                icon: 'el-icon-coin',
                title: '数据概览',
            },
        },
    ],
};