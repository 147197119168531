import request from '@/utils/request';


// 获取售后地址供应商
export function getSellerStoreReturnAddress(data) {
  return request({
    url: 'sellerReturnAddress/getSellerStoreReturnAddress',
    method: 'post',
    data,
  });
}
// 订单列表
export function getOrderList(params) {
  return request({
    url: '/order/orderList',
    method: 'get',
    params,
  });
}
// 供应商订单列表
export function supplierOrderList(params) {
  return request({
    url: '/order/supplierOrderList',
    method: 'get',
    params,
  });
}

// 删除回收订单列表
export function deleteOrder(detailId) {
  return request({
    url: `/order/delete/${detailId}`,
    method: 'delete',
  });
}

// 导出充值记录
export function exportOrderList(params) {
  return request({
    url: '/order/export',
    method: 'get',
    responseType: 'blob',
    params,
  });
}

// 全国地区 - 省市区
export function getScreeningAreaList(params) {
  return request({
    url: '/returnAddress/cityLevel',
    method: 'get',
    params,
  });
}

// 订单备注明细(售后通用)
export function getMarkOrderDetail(params) {
  return request({
    url: '/order/markOrderDetail',
    method: 'get',
    params,
  });
}

// 修改 - 订单备注(售后通用)
export function updateMarkOrder(data) {
  return request({
    url: '/order/markOrder',
    method: 'post',
    data,
  });
}

// 订单发货明细
export function getSendDetail(params) {
  return request({
    url: '/order/sendDetail',
    method: 'get',
    params,
  });
}

// 修改 - 订单发货
export function updateSendOrder(data) {
  return request({
    url: '/order/send',
    method: 'post',
    data,
  });
}

// 修改 - 订单发货 修改物流
export function updateLogisticsOrder(data) {
  return request({
    url: '/order/updateLogistics',
    method: 'post',
    data,
  });
}

// 查看物流
export function getCheckLogistics(params) {
  return request({
    url: '/order/selectLogistics',
    method: 'get',
    params,
  });
}

// 查看退货物流
export function getCheckRefundLogistics(params) {
  return request({
    url: '/orderRefund/selectRefundLogistics',
    method: 'get',
    params,
  });
}

// 查看详情
export function getOrderDetail(params) {
  return request({
    url: '/order/orderDetail',
    method: 'get',
    params,
  });
}

// 修改订单收货地址
export function updateAddress(data) {
  return request({
    url: '/order/updateAddress',
    method: 'post',
    data,
  });
}

// 供应商售后订单列表
export function getsupplierOrderRefundList(params) {
  return request({
    url: '/orderRefund/supplierOrderList',
    method: 'get',
    params,
  });
}
// 售后订单列表
export function getOrderRefundList(params) {
  return request({
    url: '/orderRefund/orderList',
    method: 'get',
    params,
  });
}

// 退货地址列表
export function getRefundAddressList() {
  return request({
    url: '/orderRefund/refundAddressList',
    method: 'get',
  });
}

// 退款申请
export function refundMoney(data) {
  return request({
    url: '/orderRefund/refundPass',
    method: 'post',
    data,
  });
}

// 拒绝退款申请
export function refundRefuse(data) {
  return request({
    url: '/orderRefund/refundRefuse',
    method: 'post',
    data,
  });
}

// 查看订单售后详情
export function getOrderRefundDetail(params) {
  return request({
    url: '/orderRefund/orderDetail',
    method: 'get',
    params,
  });
}

// 确定收货并退款
export function refundConfirmMoney(data) {
  return request({
    url: '/orderRefund/refundConfirm',
    method: 'post',
    data,
  });
}

// 查看订单售后详情
export function getAllOrderByUserId(params) {
  return request({
    url: '/order/findAllOrderByUserId',
    method: 'get',
    params,
  });
}

// 刷新订单第三方支付状态
export function reloadOrderPay() {
  return request({
    url: '/order/reloadOrderPay',
    method: 'post',
  });
}

// 刷新订单第三方退款状态
export function reloadOrderRefund() {
  return request({
    url: '/order/reloadOrderRefund',
    method: 'post',
  });
}
